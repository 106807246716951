<template>
    <img :src="src"/>
</template>

<script>


export default {
  name: "PreviewImage",
  props: ['src'],
  data : function() {
    return {

    };
  },
  watch : {

  },
  methods : {

  },
  created : function() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>