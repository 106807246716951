import Vue from 'vue'

import { BModal, BDropdown, BDropdownItemButton, BFormSelect, BFormCheckbox, BTable, BFormGroup, BFormRadio, BFormRadioGroup, BFormDatepicker } from 'bootstrap-vue'
import DatePicker from '../components/DatePicker';
import MoneyInput from '../components/MoneyInput';
import HourRangePicker from '../components/HourRangePicker';
import DateRangePicker from '../components/DateRangePicker';
import DayPicker from '../components/DayPicker';
import PreviewImage from '../components/PreviewImage';
import vSelect from 'vue-select'

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);

import Sortable from 'vue-sortable'
Vue.use(Sortable)

import draggable from 'vuedraggable'
Vue.component('draggable', draggable)

Vue.component('image-preview', PreviewImage)
Vue.component('v-select', vSelect)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item-button', BDropdownItemButton)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-table', BTable)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-modal', BModal)
Vue.component('date-picker', DatePicker)
Vue.component('money-input', MoneyInput)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('hour-range-picker', HourRangePicker)
Vue.component('day-picker', DayPicker)

import 'bootstrap-vue/dist/bootstrap-vue.css'

export { BootstrapVue } from 'bootstrap-vue'
