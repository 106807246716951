<template>
    <input class="form-control" type="text" v-model="textValue" :placeholder="placeholder" @focus="focus()" @blur="blur()" @keypress="preventNumericInput"/>
</template>

<script>

let re = /^[0-9]*(\.[0-9]{0,2})?$/;

export default {
  name: "MoneyInput",
  props: ['value', 'placeholder'],
  data : function() {
    return {
        lastValue : null,
        textValue : null,
        focussed : false
    };
  },
  watch : {
    'value' : function() {
        this.lastValue=this.value;
        this.setValue();
    },
    'textValue' : function(val) {
        if (val==null || val=='' || val=='NaN') {
            this.updateNumber(null);
        } else {
            var matched=re.exec(val);
            if (matched==null) {
                if (this.lastValue!=null) {
                    matched=re.exec(this.lastValue);
                }
                if (matched==null) {
                    this.textValue=null;
                } else {
                    this.textValue=this.lastValue;
                }

            } else {
                this.lastValue=val;
                this.updateNumber(val);
            }
        }
    }
  },
  methods : {
    setValue : function() {
        if (this.value==null) {
            if (this.textValue!=null) {
                this.textValue=null;
            }
        } else {
            try {
                if (this.focussed) {
                    if (this.value!==parseFloat(this.textValue)) {
                        this.textValue=this.value.toFixed(2);
                    }
                } else {
                    this.textValue=this.value.toFixed(2);
                }
            } catch (err) {
                //do nothing
            }

        }
    },
    updateNumber : function() {
        if (this.textValue==null || this.textValue=='') {
            this.$emit('input', null);
        } else {
            try {
                var value = parseFloat(this.textValue);
                this.$emit('input', value);
            } catch (err) {
                this.$emit('input', null);
            }
        }
    },
    preventNumericInput : function($event) {
        var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode <= 47 || keyCode >= 58) && keyCode!==46) {
            $event.preventDefault();
            return;
        }
    },
    focus : function() {
        this.focussed=true;
    },
    blur : function() {
        if (this.textValue!=null && this.textValue!='') {
            var value = parseFloat(this.textValue).toFixed(2);
            this.textValue=value+'';
        }
        this.focussed=false;
    }
  },
  created : function() {
    this.setValue();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>