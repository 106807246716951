var StorageService = {
    get : function(key, defaultValue) {
        var promise = new Promise(function(resolve) {
            var v = localStorage.getItem(key);
            if (v==null && defaultValue !== undefined) {
                v=defaultValue;
            }
            resolve(v);
        });
        return promise;
    },
    set : function(key, value) {
        var promise = new Promise(function(resolve) {
            localStorage.setItem(key, value);
            resolve();
        });
        return promise;
    },
    remove : function(key) {
        var promise = new Promise(function(resolve) {
            localStorage.removeItem(key);
            resolve();
             });
        return promise;
    },
     clear : function() {
        var promise = new Promise(function(resolve) {
         localStorage.clear();
         resolve();
          });
     return promise;
     }
};

export {StorageService as Storage};