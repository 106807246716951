import Vue from 'vue'
import moment from 'moment'
Vue.prototype.$filters = {
   toCurrency(value, skipSymbol) {
     if (typeof value !== "number") {
         return value;
     }
     var val = value.toFixed(2);
     if (!skipSymbol) {
      val='£'+val;
     }
     return val;
   },
   formatDate(value, format) {
      if (value==null) {
          return '';
      }
      return moment(value).format(format);
    }
 };