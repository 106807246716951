<template>
    <div class="hour-range-picker dropdown">
        <b-dropdown variant="default" >
            <template #button-content>
              <span>{{label}}
              </span>
            </template>
            <b-dropdown-form
            >
                <ul class="list-group">
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggleAllHours()">
                        <div>All Hours</div>
                        <div>
                            <fa v-if="model.allHours"
                                class="fa" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="hours-config list-group-item"
                        v-if="!model.allHours">
                        <div class="select-time">
                            <b-form-select v-model="model.fromHour" :options="hoursOptions" value-field="value" text-field="label"></b-form-select>
                            <span>:</span>
                            <b-form-select v-model="model.fromMin" :options="minsOptions" value-field="value" text-field="label"></b-form-select>
                        </div> <span>to</span>
                        <div class="select-time">
                            <b-form-select v-model="model.toHour" :options="hoursOptions" value-field="value" text-field="label"></b-form-select>
                            <span>:</span>
                            <b-form-select v-model="model.toMin" :options="minsOptions" value-field="value" text-field="label"></b-form-select>

                        </div>
                    </li>

                </ul>
            </b-dropdown-form>
          </b-dropdown>
    </div>
</template>

<script>

function createMinsOptions() {
    var minsOptions = [];
	for (var i=0;i<60;i++) {
		minsOptions.push({
			value : i,
			label : i<10?'0'+i:''+i
		});
	}
	return minsOptions;
}

function createHoursOptions() {
    var hoursOptions = [];
    for (var i=0;i<24;i++) {
        hoursOptions.push({
            value : i,
            label : i<10?'0'+i:''+i
        });
    }
    return hoursOptions;
}

function toTimeValue(val) {
    var str='';
    if(val<10) {
        str+='0';
    }
    str+=val;
    return str;
}

export default {
  name: "HourRangePicker",
  props: ['model'],
  data : function() {
    return {
        minsOptions : createMinsOptions(),
        hoursOptions : createHoursOptions()
    };
  },
  computed : {
    'label' : function() {
        if (this.model.allHours) {
            return 'All Hours';
        } else {
            return toTimeValue(this.model.fromHour)+':'+toTimeValue(this.model.fromMin)+' - '+toTimeValue(this.model.toHour)+':'+toTimeValue(this.model.toMin);
        }
    }
  },
  watch : {
    'model.allHours' : function(val) {
        if (!val) {
            if (this.model.fromHour==null) {
                this.model.fromHour=0;
            }
            if (this.model.fromMin==null) {
                this.model.fromMin=0;
            }
            if (this.model.toHour==null) {
                this.model.toHour=0;
            }
            if (this.model.toMin==null) {
                this.model.toMin=0;
            }
        } else {
        if (this.model.fromHour!==null) {
                this.model.fromHour=null;
            }
            if (this.model.fromMin!==null) {
                this.model.fromMin=null;
            }
            if (this.model.toHour!==null) {
                this.model.toHour=null;
            }
            if (this.model.toMin!==null) {
                this.model.toMin=null;
            }
        }
    }
  },
  methods : {
    toggleAllHours : function() {
        this.model.allHours=!this.model.allHours;
    }
  },
  created : function() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .filter-panel .days-dropdown,.filter-panel .hours-dropdown {
        padding: 0px !important;
    }

    .day-disabled {
        color: #CCCCCC;
    }

    .hours-dropdown .hours-config {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hours-dropdown .hours-config .select-time {
        display: flex;
        flex-direction: row;
    }
</style>

<style>
.hour-range-picker form, .hour-range-picker ul {
    padding: 0
}

.hours-config {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.select-time {
    display: flex;
    align-items: centre;
}

.select-time span {
    padding: 5px;
}

.select-time select {
    width: 75px;
}
</style>