import {PromiseUtils} from "@/utils/PromiseUtils";
import {Storage} from "@/utils/Storage";

var _instanceId;
var _accessToken;
var _refreshToken;

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function setToken(token) {
    var promise = new Promise(function(resolve, reject) {
        var promises = [];
        promises.push(Storage.set('TOKEN_TOKEN', token.access_token));
        promises.push(Storage.set('TOKEN_REFRESH', token.refresh_token));
        Promise.all(promises).then(function() {
            _accessToken=token.access_token;
            _refreshToken=token.refresh_token;
            resolve();
        }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}

function clearToken() {
    var promise = new Promise(function(resolve,reject) {
        var promises = [];
        promises.push(Storage.remove('TOKEN_TOKEN'));
        promises.push(Storage.remove('TOKEN_REFRESH'));
        Promise.all(promises).then(function() {
            _accessToken=null;
            _refreshToken=null;
            resolve();
        }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}

function initialiseInstanceId() {
    var promise = new Promise(function(resolve, reject) {
        Storage.get('INSTANCE_ID').then(function(id) {
            if (id==null) {
                id = uuidv4();
                Storage.set('INSTANCE_ID', id).then(function() {
                    _instanceId=id;
                    resolve(id);
                }, PromiseUtils.errorHandler(reject));
            } else {
                _instanceId=id;
                resolve(id);
            }
        });
    });
    return promise;
}

function initialiseAuthTokens() {
    var promise = new Promise(function(resolve, reject) {
        var promises=[];
        promises.push(Storage.get('TOKEN_TOKEN'));
        promises.push(Storage.get('TOKEN_REFRESH'));
        Promise.all(promises).then(function(values) {
            _accessToken=values[0];
            _refreshToken=values[1];
            resolve();
        }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}

function initialise() {
    var promises = [];
    promises.push(initialiseInstanceId());
    promises.push(initialiseAuthTokens());
    return Promise.all(promises);
}

var TokenService = {
    initialise : initialise,
    setToken : setToken,
    clearToken : clearToken,
    getInstanceId : function() {
        return _instanceId;
    },
    getAccessToken : function() {
        return _accessToken;
    },
     getRefreshToken : function() {
         return _refreshToken;
     }
};

export {TokenService as TokenService};