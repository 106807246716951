import {AlertService} from "@/services/AlertService";

var PromiseUtils = {
    errorHandler : function(reject) {
        return function(error) {
            reject(error);
        };
    },
    viewErrorHandler : function(errorMsg, func) {
        if (errorMsg==null) {
            errorMsg='There has been a technical error';
        }
        return function(err) {
            AlertService.error(errorMsg);
            if (func!=null) {
                func(err);
            }
        };
    }

}

export {PromiseUtils as PromiseUtils};