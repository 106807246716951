<template>
    <div class="dropdown">
        <b-dropdown variant="default" >
            <template #button-content>
              <span v-if="model.allDays">All Days</span> <span
                  v-if="!model.allDays"> <span
                  :class="{ 'day-disabled': !isMondaySelected}">M</span> <span
                  :class="{ 'day-disabled': !isTuesdaySelected}">T</span> <span
                  :class="{ 'day-disabled': !isWednesdaySelected}">W</span> <span
                  :class="{ 'day-disabled': !isThursdaySelected}">T</span> <span
                  :class="{ 'day-disabled': !isFridaySelected}">F</span> <span
                  :class="{ 'day-disabled': !isSaturdaySelected}">S</span> <span
                  :class="{ 'day-disabled': !isSundaySelected}">S</span>
              </span>
            </template>
            <b-dropdown-form
            >
                <ul class="list-group">
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('monday', 'mon')">
                        <div>Monday</div>
                        <div>
                            <fa v-if="isMondaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('tuesday', 'tue')">
                        <div>Tuesday</div>
                        <div>
                            <fa v-if="isTuesdaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('wednesday', 'wed')">
                        <div>Wednesday</div>
                        <div>
                            <fa v-if="isWednesdaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('thursday', 'thu')">
                        <div>Thursday</div>
                        <div>
                            <fa v-if="isThursdaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('friday', 'fri')">
                        <div>Friday</div>
                        <div>
                            <fa v-if="isFridaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('saturday', 'sat')">
                        <div>Saturday</div>
                        <div>
                            <fa v-if="isSaturdaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="toggle('sunday', 'sun')">
                        <div>Sunday</div>
                        <div>
                            <fa v-if="isSundaySelected" icon="check-square"></fa>
                        </div>
                    </li>
                </ul>
            </b-dropdown-form>
          </b-dropdown>
    </div>
</template>

<script>
export default {
  name: "DayPicker",
  props: ['model', 'format'],
  methods : {
    getValue: function(long, short) {
        if (this.format=='short') {
            return this.model[short];
        } else {
            return this.model[long];
        }
    },toggle : function(long, short) {
         if (this.format=='short') {
             this.model[short]=!this.model[short];
         } else {
             this.model[long]=!this.model[long];
         }
         this.model.allDays=this.isMondaySelected&&this.isTuesdaySelected&&this.isWednesdaySelected&&this.isThursdaySelected&&this.isFridaySelected&&this.isSaturdaySelected&&this.isSundaySelected;
    }
  },
  computed : {
    isMondaySelected : function() {
        return this.getValue('monday', 'mon');
    },
    isTuesdaySelected : function() {
        return this.getValue('tuesday', 'tue');
    },
     isWednesdaySelected : function() {
         return this.getValue('wednesday', 'wed');
     },
      isThursdaySelected : function() {
          return this.getValue('thursday', 'thu');
      },
       isFridaySelected : function() {
           return this.getValue('friday', 'fri');
       },
       isSaturdaySelected : function() {
          return this.getValue('saturday', 'sat');
      },
      isSundaySelected : function() {
         return this.getValue('sunday', 'sun');
     },
    allDays : function() {
        return this.model.monday && this.model.tuesday && this.model.wednesday && this.model.thursday && this.model.friday && this.model.saturday && this.model.sunday;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .day-disabled {
        color: #CCCCCC;
    }

    .list-group .actionable {
         display: flex;
         align-items: center;
    }

    .list-group .actionable>div:first-child {
         flex-grow: 10;
    }
</style>