<template>
    <div class="dropdown">
        <b-dropdown variant="default" ref="dropdown">
            <template #button-content>
              <span class="selected-label">{{label}}</span>
            </template>
            <b-dropdown-form
            >
                <ul class="list-group">
                    <li class="list-group-item actionable clickable"
                        @click.stop.prevent="select(option)"
                        v-for="(option,index) in optionsToShow" :key="index">
                        <div>{{option.label}}</div>
                        <div>
                            <fa v-if="selectedOption==option" icon="check-square"></fa>
                        </div>
                    </li>
                    <li class="list-group-item" v-if="isCustomSelected">
                        <div><b>From</b></div>
                        <div>
                            <datepicker v-model="startDate"></datepicker>
                        </div>
                    </li>
                    <li class="list-group-item" v-if="isCustomSelected">
                        <div><b>To</b></div>
                        <div>
                            <datepicker v-model="endDate"></datepicker>
                        </div>
                    </li>
                    <li class="list-group-item" v-if="isCustomSelected">
                        <button class="btn btn-primary w-100" @click.stop.prevent="close()">Select</button>
                    </li>
                </ul>
            </b-dropdown-form>
          </b-dropdown>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

function format(date) {
    if (date==null) {
        return null;
    } else {
        return moment(moment(date).format("YYYYMMDD"),"YYYYMMDD");
    }
}

export default {
  name: "DateRangePicker",
  props: ['value'],
  components : {
    Datepicker
  },
  data : function() {
    var d = {
        options : [
            {label : 'Custom Range', value : 'Custom Range'},
            {label : 'Today', value : 'Today'},
            {label : 'Yesterday', value : 'Yesterday'},
            {label : 'Last 7 Days', value : 'Last 7 Days'},
            {label : 'Last 30 Days', value : 'Last 30 Days'}
        ],
        selectedOption : null,
        startDate : null,
        endDate : null
    };
    if (this.value!=null) {
            this.startDate=this.value.startDate;
            this.endDate=this.value.endDate;
            for (var i=0;i<d.options.length;i++) {
                if (d.options[i].value==this.value.dateRange) {
                    d.selectedOption=d.options[i];
                    break;
                }
            }
        }
    return d;
  },
  computed : {
    isCustomSelected : function() {
        return this.isCustom(this.selectedOption);
    },
    optionsToShow : function() {
        if (this.selectedOption==null || this.selectedOption.value!=='Custom Range') {
            return this.options;
        } else {
            return [this.options[0]];
        }
    },
    label : function() {
        if (this.selectedOption==null) {
            return 'Select...';
        } else if (this.isCustomSelected) {
            return moment(this.startDate).format('ddd DD MMM YYYY')+' - '+moment(this.endDate).format('ddd DD MMM YYYY');
        } else {
            return this.selectedOption.label;
        }
    }

  },
  watch : {
    'selectedOption' : function() {
        this.emitUpdate();
    },
     'startDate' : function() {
         this.emitUpdate();
     },
     'endDate' : function() {
         this.emitUpdate();
     },
     'value' : function() {
        this.updateValue();
     }
  },
  methods : {
    updateValue : function() {
        if (this.value!=null) {
            if (this.startDate!=this.value.startDate) {
                this.startDate=this.value.startDate;
            }
            if (this.endDate!=this.value.endDate) {
                this.endDate=this.value.endDate;
            }

            if (this.value.dateRange==null) {
                this.selectedOption=null;
            } else {
                for (var i=0;i<this.options.length;i++) {
                    if (this.options[i].value==this.value.dateRange) {
                        this.selectedOption=this.options[i];
                        break;
                    }
                }
            }
        } else {
            this.startDate==null;
            this.endDate==null;
            this.selectedOption=null;
        }
    },
    emitUpdate : function() {
        this.value.startDate=format(this.startDate);
        this.value.endDate=format(this.endDate);
        this.value.dateRange=this.selectedOption!=null?this.selectedOption.value:null;
        this.$emit('input', this.value);
    },
    isCustom : function(option) {
        return option!==null && option.value=='Custom Range';
    },select : function(option) {
        if (this.selectedOption==option) {
            this.selectedOption=null;
            this.startDate=null;
            this.endDate=null;
        } else {
             this.selectedOption=option;
             if (option.value=='Custom Range') {
                this.startDate=new Date();
                this.endDate=new Date();
             }
        }

         if (this.selectedOption!=null && option.value!=='Custom Range') {
            this.$refs.dropdown.hide();
         }
    },
    close : function() {
        this.$refs.dropdown.hide();
    },
    sink : function() {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .b-dropdown-form  {
        padding: 0px !important;
    }

    .list-group-item {
        min-width: 220px;
    }

    .selected-label {
        margin-right: 10px;
    }
</style>