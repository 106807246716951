<template>
    <b-form-datepicker ref="picker" :placeholder="placeholder" v-model="dateValue" value-as-date ></b-form-datepicker>
</template>

<script>
export default {
  name: "DatePicker",
  props: ['value', 'placeholder'],
  data : function() {
    return {
        dateValue : null
    };
  },
  watch : {
    'value' : function() {
        this.setValue();
    },
    'dateValue' : function(val) {
        this.updateDate(val);
    }
  },
  methods : {
    setValue : function() {
        if (this.value==null) {
            if (this.dateValue!=null) {
                this.dateValue=null;
            }
        } else {
            var d=new Date(this.value);
            if (this.dateValue!=d) {
                this.dateValue=new Date(this.value);
            }
        }
    },
    updateDate : function(val) {
        var emittable=null;
        if (val!=null) {
            emittable=val.getTime();
        }
        this.$emit('input', emittable);
    }
  },
  created : function() {
    this.setValue();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>