var ObservableFactory = {
    makeObservable : function(obj) {
        var listeners = [];
        obj.addListener=function(listener) {
            listeners.push(listener);
            return function() {
                var index = listeners.indexOf(listener);
                if (index>=0) {
                    listeners.splice(index, 1);
                }
            };
        };
        return function(event) {
            listeners.forEach(function(l) {
                l(event);
            });
        };
    }

}

export {ObservableFactory as ObservableFactory};