import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faUber, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import {faMinus, faPlus, faBars, faCheckCircle, faCircle, faAngleLeft, faAngleDown, faUndo, faSave, faTachometerAlt, faStoreAlt, faCopyright, faMapMarkerAlt, faDesktop, faArrowCircleLeft, faUser, faPhone, faMoneyBill, faCreditCard, faTicketAlt, faCar} from '@fortawesome/free-solid-svg-icons'
import {faBuilding, faRecycle, faClock, faSquare, faEllipsisH, faShoppingBag, faTabletAlt, faEdit, faEnvelope, faUtensils, faBan, faTags, faChartBar, faSearch, faReceipt, faCheckSquare, faInfoCircle, faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import {faUpload, faGripVertical, faBreadSlice} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, faUber, faTwitter, faInstagram, faMinus, faPlus, faBars, faCheckCircle, faCircle, faAngleLeft, faAngleDown, faUndo, faSave, faTachometerAlt, faStoreAlt, faCopyright, faMapMarkerAlt, faDesktop, faArrowCircleLeft, faUser, faPhone, faMoneyBill, faCreditCard, faTicketAlt, faCar);
library.add(faBuilding, faRecycle, faClock, faSquare, faEllipsisH, faShoppingBag, faTabletAlt, faEdit, faEnvelope, faUtensils, faBan, faTags, faChartBar, faSearch, faReceipt, faCheckSquare, faInfoCircle, faCalendarAlt);
library.add(faUpload, faGripVertical, faBreadSlice);

export default FontAwesomeIcon;
