import Vue from 'vue'
import App from './App.vue'
import './plugins/filters'

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import {$,jQuery} from 'jquery';
// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

Vue.config.productionTip = false

import router from './router';

/*
export default {
    name      : 'myComponent',

    components: {
        VueAutonumeric,
    },
}
*/

import money from 'v-money'
Vue.use(money, {precision: 2,
                decimal: '.',
          thousands: '',
          prefix: '£',})

import FontAwesomeIcon from './plugins/font-awesome'
Vue.component('fa', FontAwesomeIcon);

import { BootstrapVue } from './plugins/bootstrap'
Vue.use(BootstrapVue);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import {AuthenticationService} from '@/services/AuthenticationService';

import axios from 'axios'

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    if (process.env.APP_URL!=null && process.env.APP_URL!='') {
        config.url=process.env.APP_URL+config.url;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });


import './theme/AdminLTE.scss'

/* Theme variables */
import './theme/variables.css';

AuthenticationService.initialise().then(function() {
    new Vue({
      router,
      render: h => h(App),
    }).$mount('#app')
});
