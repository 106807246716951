import {TokenService} from "@/services/TokenService";
import {PromiseUtils} from "@/utils/PromiseUtils";
import {RestHelper} from "@/utils/RestHelper";
import {ObservableFactory} from "@/utils/ObservableFactory";

const CLIENT = "acme";
const CLIENT_SECRET = "acmesecret";

var _init=false;

function readData(resolve, reject, that) {
    RestHelper.get('/wb/api/admin/data').then(function (response) {
        var updated = !AuthenticationService.model.isLoggedIn || AuthenticationService.model.isActivated!=response.data.activated;
        AuthenticationService.model.isLoggedIn=true;
        AuthenticationService.model.loggedInUser=response.data;
        if (updated) {
            callListeners(true);
        }
        resolve(that);
      })
      .catch(function () {
        AuthenticationService.model.isPasswordExpired=false;
        AuthenticationService.model.activated=false;
        AuthenticationService.model.isLoggedIn=false;
        AuthenticationService.model.loggedInUser=null;
        _init=true;
        resolve(that);
      });
}

var AuthenticationService = {
    getInstanceId : function() {
        return TokenService.getInstanceId();
    },
    initialise : function() {
        var that=this;
        var promise = new Promise(function(resolve, reject) {
            if (_init) {
                resolve(that);
            } else {
                TokenService.initialise().then(function() {
                      readData(resolve, resolve, that);
                  }, PromiseUtils.errorHandler(reject));


            }
        });
        return promise;
    },
    model : {
        isLoggedIn : false,
        loggedInUser : null,
        passwordReset : function(model) {
            var that=this;
            var promise = new Promise(function(resolve, reject) {
            var url='/wb/api/app/account/passwordreset';
            RestHelper.post(url, model)
              .then(function (response) {
                if (response.data.success) {
                    var promise2 = new Promise(function(resolve2) {
                        readData(resolve2, resolve2, that);
                    });
                    promise2.then(function() {
                        resolve(response.data);
                    });
                } else {
                    resolve(response.data);
                }
              })
              .catch(function (error) {
                 reject(error);
              });
           });
            return promise;
        },
        logout : function() {
            var that=this;
            var promise = new Promise(function(resolve, reject) {
            TokenService.clearToken().then(function() {
                var url='/wb/api/logout';
                RestHelper.get(url)
                  .then(function () {
                    that.isLoggedIn=false;
                    that.loggedInUser=null;
                    callListeners(false);
                    resolve();
                  })
                  .catch(function (error) {
                     reject(error);
                  });
            }).catch(function (error) {
               reject(error);
            });

            });
          return promise;
        },
        login : function(username, password) {
            var that=this;
            var promise = new Promise(function(resolve, reject) {
                var bodyFormData = new FormData();
                bodyFormData.set('grant_type', "password");
                bodyFormData.set('username', username);
                bodyFormData.set('password', password);
                var RestHelperConfig = {
                  ignoreInterceptor : true,
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'Authorization': 'Basic '+window.btoa(CLIENT+':'+CLIENT_SECRET)
                  }
                };
                RestHelper.post('/wb/api/login', bodyFormData, RestHelperConfig)
                  .then(function (response) {
                    TokenService.setToken(response.data).then(function() {
                        if (response.headers['credentials-expired']=='true') {
                            AuthenticationService.model.isPasswordExpired=true;
                            AuthenticationService.model.isLoggedIn=true;
                            AuthenticationService.model.loggedInUser=null;
                            _init=true;
                            resolve(that);
                        } else {
                            readData(resolve, reject, that);
                        }
                    });

                  })
                  .catch(function (error) {
                    if (error.response.status === 401) {
                        that.isLoggedIn=false;
                        resolve(that.isLoggedIn);
                    } else {
                        reject(error);
                    }
                  });
            });
            return promise;
        },
         signup : function(request) {
             var that=this;
             var promise = new Promise(function(resolve, reject) {
                 var RestHelperConfig = {
                   ignoreInterceptor : true,
                   headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Basic '+window.btoa(CLIENT+':'+CLIENT_SECRET)
                   }
                 };
                 RestHelper.post('/wb/api/signup/submit', request, RestHelperConfig)
                   .then(function (response) {
                     if (response.data.success) {
                        var promise2 = new Promise(function(resolve2, reject2) {
                            TokenService.setToken(response.data).then(function() {
                                 readData(resolve2, reject2, that);
                             });
                        });
                        promise2.then(function(res) {
                            resolve({
                                success : true,
                                result : res
                            }, PromiseUtils.errorHandler(reject));
                        });

                     } else {
                        resolve(response.data);
                     }

                   })
                   .catch(function (error) {
                     if (error.response.status === 401) {
                         that.isLoggedIn=false;
                         resolve({
                            success : false,
                            errors : []
                         });
                     } else {
                         reject(error);
                     }
                   });
             });
             return promise;
         }

    }
};

var callListeners = ObservableFactory.makeObservable(AuthenticationService);


export {AuthenticationService as AuthenticationService};