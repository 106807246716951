import axios from 'axios';
import {TokenService} from "@/services/TokenService";
import {PromiseUtils} from "@/utils/PromiseUtils";

const CLIENT = "acme";
const CLIENT_SECRET = "acmesecret";

axios.interceptors.request.use(function (config) {
    var instanceId = TokenService.getInstanceId();
    if (instanceId!=null) {
        config.headers['Instance-ID'] =  instanceId;
    }
    if (config.ignoreInterceptor) {
        return config;
    }
    var token = TokenService.getAccessToken();
    if (token!=null) {
        config.headers.Authorization =  'Bearer '+token;
    }
    return config;
});

function refreshToken() {
    var promise = new Promise(function(resolve, reject) {
        var bodyFormData = new FormData();
        bodyFormData.set('grant_type', "refresh_token");
        bodyFormData.set('refresh_token', TokenService.getRefreshToken());
        var axiosConfig = {
          ignoreInterceptor : true,
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Basic '+window.btoa(CLIENT+':'+CLIENT_SECRET)
          }
        };
        axios.post('/oauth/token', bodyFormData, axiosConfig)
          .then(function (response) {
            TokenService.setToken(response.data).then(function() {
                resolve();
            }, PromiseUtils.errorHandler(reject));
          }, PromiseUtils.errorHandler(reject));
    });
    return promise;
}

function execute(config, firstAttempt, resolve, reject) {
    axios(config).then(function(response) {
         resolve(response);
     }, function(error) {
        if (error.response.status==401 && firstAttempt) {
            refreshToken().then(function() {
                execute(config, false, resolve, reject);
            }, PromiseUtils.errorHandler(reject));
        } else {
            reject(error);
        }

     });
}

var RestHelper = {
    get : function(url) {
        var promise = new Promise(function(resolve, reject) {
            var config = {
                method : 'get',
                url : url
            };
            execute(config, true, resolve, reject);
        });
        return promise;
    },
    postFile : function(url, file, axiosConfig) {
        var promise = new Promise(function(resolve, reject) {
            var formData = new FormData();
            formData.append("file", file);
            var config = {
                method : 'post',
                url : url,
                data : formData,
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            };
            if (axiosConfig!=null) {
                Object.assign(config, axiosConfig);
            }
            execute(config, true, resolve, reject);
        });
        return promise;
    },
    post : function(url, body, axiosConfig) {
        var promise = new Promise(function(resolve, reject) {
            var config = {
                method : 'post',
                url : url,
                data : body
            };
            if (axiosConfig!=null) {
                Object.assign(config, axiosConfig);
            }
            execute(config, true, resolve, reject);
        });
        return promise;
    },
    put : function(url, body, axiosConfig) {
        var promise = new Promise(function(resolve, reject) {
            var config = {
                method : 'put',
                url : url,
                data : body
            };
            if (axiosConfig!=null) {
                Object.assign(config, axiosConfig);
            }
            execute(config, true, resolve, reject);
        });
        return promise;
    },
    delete : function(url) {
        var promise = new Promise(function(resolve, reject) {
            var config = {
                method : 'delete',
                url : url
            };
            execute(config, true, resolve, reject);
        });
        return promise;
    }
}

export {RestHelper as RestHelper};