import Vue from 'vue'
import VueRouter from 'vue-router'
import {AlertService} from '@/services/AlertService';
import {AuthenticationService} from '@/services/AuthenticationService';

Vue.use(VueRouter)

const routes = [
      {
          path: "/login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "home" */ "@/views/account/Login"),
            meta: {
               openAuth : true
             }
      },
      {
            path: "/signup",
            name: "signup",
            component: () =>
              import(/* webpackChunkName: "home" */ "@/views/account/Signup"),
              meta: {
                 openAuth : true
               }
        },
        {
          path: '/website/config',
          name : 'websitePreview',
          component: () =>
              import(/* webpackChunkName: "home" */ "@/views/website/WebsiteEditor")

        },
      {
        path: "/app",
        name: "app",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/page/AppTemplate"),
        children: [
                {
                  path: '/home',
                  name : 'home',
                  meta: {

                    },
                  component: () =>
                      import(/* webpackChunkName: "home" */ "@/views/home/Home")

                },
                {
                  path: '/social',
                  name : 'social',
                  component: () =>
                      import(/* webpackChunkName: "home" */ "@/views/social/Social")

                },
                 {
                   path: '/club',
                   name : 'club',
                   component: () =>
                       import(/* webpackChunkName: "home" */ "@/views/club/Club")

                 },
                 {
                   path: '/dashboard',
                   name : 'dashboard',
                   component: () =>
                       import(/* webpackChunkName: "home" */ "@/views/dashboard/Dashboard")

                 },
                   {
                     path: '/brand',
                     name : 'brand',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/business/Brand")

                   },
                   {
                     path: '/locationdetails',
                     name : 'location',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/business/Location")

                   },
                   {
                    path: '/locationcontactdetails',
                    name : 'locationContactDetails',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/business/LocationContactDetailsPage")

                  },
                  {
                   path: '/locationopeningtimes',
                   name : 'locationOpeningTimes',
                   component: () =>
                       import(/* webpackChunkName: "home" */ "@/views/business/LocationOpeningTimesPage")

                 },
                {
                 path: '/orderworkflow',
                 name : 'locationPosConfig',
                 component: () =>
                     import(/* webpackChunkName: "home" */ "@/views/business/LocationPosConfigPage")

               },
                    {
                     path: '/companydetails',
                     name : 'companyDetails',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/business/LocationCompanyDetailsPage")

                   },
                   {
                     path: '/website',
                     name : 'website',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/website/Website")

                   },
                   {
                     path: '/onlineordering',
                     name : 'onlineordering',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/onlineordering/OnlineOrdering")

                   },
                   {
                    path: '/olo',
                    name : 'olo',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/onlineordering/OnlineOrderingControlPanel")

                  },
                   {
                     path: '/onlineordering/ubereats/:id',
                     name : 'ubereats',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/onlineordering/UberEats")

                   },
                   {
                     path: '/onlineordering/cardpayments/:id',
                     name : 'cardpayments',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/onlineordering/CardPayments")

                   },
                   {
                     path: '/onlineordering/pickup/:id',
                     name : 'pickup',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/onlineordering/Pickup")

                   },
                   {
                     path: '/onlineordering/delivery/:id',
                     name : 'pickup',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/onlineordering/Delivery")

                   },
                   {
                     path: '/devices',
                     name : 'devices',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/devices/DeviceList")

                   },
                   {
                     path: '/device/:id',
                     name : 'device',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/devices/DeviceEdit")

                   },
                   {
                     path: '/unavailable',
                     name : 'unavailable',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/unavailable/UnavailableItems")

                   },
                   {
                     path: '/discounts',
                     name : 'discounts',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/discounts/DiscountList")

                   },
                   {
                    path: '/discount/create/discount',
                    name : 'creatediscount',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/discounts/DiscountEdit")
                  },
                  {
                      path: '/discount/create/promoCode',
                      name : 'createpromo',
                      component: () =>
                          import(/* webpackChunkName: "home" */ "@/views/discounts/DiscountEdit")
                    },
                    {
                      path: '/discount/create/reward',
                      name : 'createreward',
                      component: () =>
                          import(/* webpackChunkName: "home" */ "@/views/discounts/RewardEdit")
                    },
                   {
                     path: '/discount/discount/:id',
                     name : 'discount',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/discounts/DiscountEdit")
                   },
                   {
                    path: '/discount/promoCode/:id',
                    name : 'discount',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/discounts/DiscountEdit")
                  },
                  {
                      path: '/discount/reward/:id',
                      name : 'discount',
                      component: () =>
                          import(/* webpackChunkName: "home" */ "@/views/discounts/RewardEdit")
                    },
                   {
                     path: '/orders',
                     name : 'orders',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/orders/OrderSearch")
                   },
                   {
                     path: '/stafflist',
                     name : 'stafflist',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/users/StaffList")
                   },
                   {
                     path: '/staff',
                     name : 'editstaff',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/users/StaffEdit")
                   },
                   {
                     path: '/staff/:id',
                     name : 'createstaff',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/users/StaffEdit")
                   },
                   {
                     path: '/reports/deliveries',
                     name : 'deliveryreport',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/reports/DeliveryHeatmapReport")
                   },
                   {
                     path: '/order/:id',
                     name : 'order',
                     component: () =>
                         import(/* webpackChunkName: "home" */ "@/views/orders/ViewOrder")
                   },
                  {
                    path: '/dailysummary',
                    name : 'dailysummary',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/orders/DailySummary")
                   },
                  {
                    path: '/dashboard/:id/addreport',
                    name : 'addreport',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/dashboard/AddReport")
                   },
                  {
                    path: '/reports/runreport',
                    name : 'runreport',
                    component: () =>
                        import(/* webpackChunkName: "home" */ "@/views/dashboard/AddReport")
                   },
                      {
                        path: '/menumanagement',
                        name : 'menumanagement',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/MenuHomepage")
                       },
                      {
                        path: '/menuworkarea',
                        name : 'menuworkarea',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/EditHomepage")
                       },
                      {
                        path: '/menuworkarea/menu/:id',
                        name : 'menuworkarea_menu',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Menu")
                       },
                      {
                        path: '/menuworkarea/category/:id',
                        name : 'menuworkarea_category',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Category"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/product/:id',
                        name : 'menuworkarea_product',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Product"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/category/:categoryId/product',
                        name : 'menuworkarea_createcategoryproduct',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Product"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/variation/:variationId/product',
                        name : 'menuworkarea_createvariationproduct',
                       component: () =>
                           import(/* webpackChunkName: "home" */ "@/views/menu/Product"),
                       props : true
                      },
                      {
                          path: '/menuworkarea/modifiergroup/:groupingId/product',
                          name : 'menuworkarea_createmodifiergroupproduct',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Product"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/configureditem/:id',
                        name : 'menuworkarea_configureditem',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/ConfiguredItem"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/category/:categoryId/configureditem',
                        name : 'menuworkarea_createcategoryconfigureditem',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/ConfiguredItem"),
                        props : true
                       },
                      {
                        path: '/menuworkarea/variation/:id',
                        name : 'menuworkarea_variation',
                        component: () =>
                            import(/* webpackChunkName: "home" */ "@/views/menu/Variation"),
                        props : true
                       },
                          {
                            path: '/menuworkarea/taxrates',
                            name : 'menuworkarea_taxrates',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/TaxRates"),
                            props : true
                           },
                          {
                            path: '/menuworkarea/taxrate',
                            name : 'add_menuworkarea_taxrate',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/TaxRateEdit"),
                            props : true
                           },
                          {
                            path: '/menuworkarea/taxrate/:id',
                            name : 'menuworkarea_taxrate',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/TaxRateEdit"),
                            props : true
                           },
                          {
                            path: '/menuworkarea/modifiergroup/:id',
                            name : 'menuworkarea_modifiergroup',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/ModifierGroup"),
                            props : true
                           },
                          {
                            path: '/menuworkarea/category/:categoryId/modifiergroup',
                            name : 'menuworkarea_createmodifiergroup_category',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/ModifierGroup"),
                            props : true
                           },
                          {
                            path: '/menuworkarea/product/:productId/modifiergroup',
                            name : 'menuworkarea_createmodifiergroup_product',
                            component: () =>
                                import(/* webpackChunkName: "home" */ "@/views/menu/ModifierGroup"),
                            props : true
                           },
                              {
                                path: '/menuworkarea/variation/:variationId/modifiergroup',
                                name : 'menuworkarea_createmodifiergroup_variation',
                                component: () =>
                                    import(/* webpackChunkName: "home" */ "@/views/menu/ModifierGroup"),
                                props : true
                               }
        ]
     }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
    AlertService.clear();
  if (to.path=='/') {
    if (AuthenticationService.model.isLoggedIn) {
            next({path:'/home'});
    } else {
        next({path:'/login'});
        }
  } else if (to.matched.length==0) {
    next({path:'/'});
  } else if (to.meta.openAuth) {
    next();
  } else if (AuthenticationService.model.isLoggedIn) {
    if (to.meta.roles) {
        var found=false;
        if (Array.isArray(to.meta.roles)) {
            for (var i=0;i<to.meta.roles.length;i++) {
                if (to.meta.roles[i]==AuthenticationService.model.loggedInUser.role) {
                    found=true;
                    break;
                }
            }
        } else {
            if (to.meta.roles==AuthenticationService.model.loggedInUser.role) {
                found=true;
            }
        }
        if (found) {
            return next();
        } else {
            next({path:'/'});
        }
    } else {
        next();
    }
  } else {
    next({name:'login'});
  }
});

export default router
