function autoRemoveAlerts() {
    var now = Date.now();
    for (var i=AlertService.model.alerts.length-1;i>=0;i--) {
        var ms = now-AlertService.model.alerts[i].createTime;
        if (ms>2000) {
            AlertService.model.alerts[i].close();
        }
    }
}

function addAlert(type, icon, title, description) {
    AlertService.model.alerts.push({
        type : type,
        title : title,
        icon : icon,
        description : description,
        createTime : Date.now(),
        close : function() {
            var index = AlertService.model.alerts.indexOf(this);
            if (index > -1) {
                AlertService.model.alerts.splice(index, 1);
            }
        }
    });
    autoRemoveAlerts();
}

var AlertService = {
            model : {
                alerts : []
            },
            clear : function() {
                var alerts=[];

                this.model.alerts.forEach(function(a) {
                    var now = Date.now();
                    var ms = now-a.createTime;
                    if (ms<1000) {
                        alerts.push(a);
                    }
                });
                this.model.alerts=alerts;
            },
            info : function(title, description) {
                addAlert('info', 'info', title, description);
            },danger : function(title, description) {
                addAlert('danger', 'ban', title, description);
            },warning : function(title, description) {
                addAlert('warning', 'warning', title, description);
            },success : function(title, description) {
                addAlert('success', 'check', title, description);
            },error : function(title, errors, preErrorDescription) {
                if (!preErrorDescription || preErrorDescription.length==0) {
                    preErrorDescription='';
                } else {
                    preErrorDescription=preErrorDescription+'</br>';
                }
                if (errors!=null && errors.length>0) {
                    for (var i=0;i<errors.length-1;i++) {
                        preErrorDescription=preErrorDescription+errors[i].message+'</br>';
                    }
                    preErrorDescription=preErrorDescription+errors[errors.length-1].message;
                }
                this.danger(title, preErrorDescription);
            }

            };

export {AlertService as AlertService};